import { Link, useLocation } from "react-router-dom";
import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import sidebarLinks from '../../components/sidebar/video-gallery';
import "../../styles/innerpage.css";
import '../../styles/headers.css';
import React from "react";


export const InstagramApi = () => {
  const location = useLocation();

  const url = "https://graph.facebook.com/v22.0/instagram_oembed?url=https://www.instagram.com/p/fA9uwTtkSN/&access_token=491d9a2a18274136c8d83ae3f5b4c95d"


  const data = fetch(url)
    .then(response => response.json())
    .then(data => {
      console.log(data); // This will log the JSON data to the console
      return data; // You can return the data if needed
    })
    .catch(error => {
      console.error('Error:', error);
    });

  console.log(data);


  return (
    <div>
      <Navigation />
      <div id="video_gallery" className="container-fluid col-md-12 page-header"></div>

      <div className="wrapper">
        <div className="row">
          <div id="quick_links" className="d-none d-sm-block d-sm-none d-md-block col-md-4 col-lg-3">
            <div className="sticky-top">
              <h4 className="mt-3 quick-links">In this Section</h4>
              <div className="vertical-menu">
                {sidebarLinks.map((link, index) => {
                  return (
                    <div key={index} className={location.pathname === link.path ? "active-menu" : "inactive"} >
                      <Link className="text-sm" to={link.path}>
                        {link.label}
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div id="content" className="col-md-8 col-lg-9">
            <div className="row">
              <div className="col-md-10">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/">Video Gallery</Link>
                  </li>

                  <li className="breadcrumb-item">
                    <Link to="#">
                      <strong>General</strong>
                    </Link>
                  </li>
                </ol>

              </div>
            </div>

            <div className="col-md-12">

              test:

            </div>

          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
