import { Navigation } from "../../components/Navigation";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { useState } from 'react';
import '../../styles/headers.css';
import '../../styles/innerpage.css';

export const BellSchedule = () => {
  const [showPopup, setShowPopup] = useState(true);

  return (
    <div>
      <Navigation />
      <div id="bell_schedule" className="container-fluid col-md-12 page-header"></div>
      <div id="one-page" className="wrapper">
        <div className="row">
          <div className="col-md-10">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="#">Key Info</Link></li>
              <li className="breadcrumb-item"><Link to="#"><strong>Bell Schedule</strong></Link></li>
            </ol>
            <h4 className="mt-3 page-title">IKNS Bell Schedule</h4>
          </div>
        </div>


        <p className="text-sm">On Tuesdays, students in Grades 1 to 12 follow a short day schedule where they are dismissed earlier than the rest of the week. This adjustment has been made to allocate time within the schedule for weekly collaborative planning meetings among our faculty members.</p>
        <p className="text-sm">The Tuesday bell schedule is referred to as the Short Day Schedule and the bell schedule for Sunday, Monday, Wednesday and Thursday is referred to as the Regular Day Schedule. Below are the Starting and Dismissal timings for students in the different sections of the school, for both the regular school day and and the short day.</p>
        <p className="text-sm">The KG students follow a regular schedule Sunday to Thursday.</p>


        <div className="mt-5 d-flex justify-content-between align-items-center">
          <p className="fw-bold mb-0">Kindergarten and Elementary School Bell Schedule</p>
          <small><a href="https://drive.google.com/file/d/1uU8OrlVdu-csD22osRuCqOdpk6muKsv6/view" className="text-primary" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" /> </svg> Print</a></small>
        </div>



        <table className="table table-bordered text-xs align-middle">
          <thead>
            <tr className="align-middle text-center">
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>KG1 & KG2</th>
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }} colSpan={2}>Grades 1, 2 & 3</th>
              <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }} colSpan={2}>Grades 4 & 5</th>
            </tr>

            <tr className="align-middle text-center">
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Sun, Mon, Tues, Wed & Thu</th>
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Sun, Mon, Wed & Thu<br />(Regular Day)</th>
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Tuesday<br />Short Schedule)</th>
              <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }}>Sun, Mon, Wed & Thu<br />(Regular Day)</th>
              <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }}>Tuesday<br />(Short Schedule)</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td style={{ backgroundColor: '#f0f0f0' }}>Morning Assembly<br /> 07:40 - 07:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Morning Assembly<br /> 07:40 - 07:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Morning Assembly<br /> 07:40 - 07:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Morning Assembly<br /> 07:40 - 07:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Morning Assembly<br /> 07:40 - 07:50</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br /> 07:50- 08:30</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br /> 07:55 - 08:45</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br /> 07:50- 08:30</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 1<br /> 07:55 - 08:45</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 1<br /> 07:50- 08:30</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />08:30 - 09:10</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />08:50 - 09:40</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />08:30 - 09:10</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 2<br />08:50 - 09:40</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 2<br />08:30 - 09:10</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Snack Break<br />09:10 - 09:35</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Snack Break<br />09:40 - 10:15</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Snack Break<br />09:10 - 09:35</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 3<br />09:45 - 10:30</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 3<br />09:10 - 9:50</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />09:35 - 10:15</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />10:20 - 11:05</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />09:35 - 10:15</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Snack/Break<br />10:30 - 11:05</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Snack/Break<br />9:50 - 10:15</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />10:15 - 10:55</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />11:10 - 11:55</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />10:15 - 10:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 4<br />11:10 - 11:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 4<br />10:15 - 10:55</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />10:55 - 11:10</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />11:55 - 12:10</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />10:55 - 11:10</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 5<br />12:00 - 12:45</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 5<br />10:55 - 11:35</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />11:10 - 11:50</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />12:15 - 13:00</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />11:10 - 11:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />12:45 - 13:00</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />11:35 - 11:50</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />11:50- 12:30</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />13:05 - 13:50</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />11:50- 12:30</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 6<br />13:05 - 13:50</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 6<br />11:50 - 12:30</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Program<br />Tuesday (13:25)</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Program<br />14:00 - 14:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Program<br />12:35 - 13:25</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Program<br />14:00 - 14:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Program<br />12:35 - 13:25</td>
            </tr>

          </tbody>
        </table>


        <p className="text-sm fst-italic text-link">Students must be on campus before 7:40 am
          School ID cards will be distributed to NEW students and Parents/Guardians at the start of the school year
          Current students and Parents/Guardians will continue to use the ID cards that they currently have
          Parents/Guardians must show their School ID cards to pick up their children</p>




        <div className="mt-5 d-flex justify-content-between align-items-center">
          <p className="fw-bold mb-0">Middle & Secondary School Bell Schedule</p>
          <small><a href="https://drive.google.com/file/d/1NJPTMh-mljMBuDeTzv9wmqaNRpW5ry6q/view" className="text-primary" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" /> </svg> Print</a></small>
        </div>

        <table className="table table-bordered text-xs align-middle">
          <thead>
            <tr className="align-middle text-center">
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }} colSpan={2}>Middle School (G6 - G8)</th>
              <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }} colSpan={2}>Secondary School (G9 - G12)</th>
            </tr>

            <tr className="align-middle text-center">
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Sun, Mon, Wed & Thu<br />(Regular Day)</th>
              <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Tuesday<br />(Short Schedule)</th>
              <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }}>Sun, Mon, Wed & Thu<br />(Regular Day)</th>
              <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }}>Tuesday<br />(Short Schedule)</th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Homeroom<br />07:45 – 07:55</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Homeroom<br />07:45 – 07:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Homeroom<br />07:45 – 07:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Homeroom<br />07:45 – 07:55</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br />08:00 – 08:55</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br />08:00 – 08:45</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 1<br />08:00 – 08:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 1<br />08:00 – 08:45</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />09:00 – 10:00</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />08:50 – 09:35</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 2<br />09:00 – 09:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 2<br />08:50 – 09:35</td>
            </tr>



            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />10:05 – 11:05</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />09:40 – 10:30</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />09:55 – 10:10</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />09:35 – 09:55</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />11:05 – 11:35</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />10:30 – 10:50</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 3<br />10:15 – 11:15</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 3<br />10:00 – 10:45</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />11:40 – 12:40</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />10:55 – 11:40</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 4<br />11:20 – 12:20</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 4<br />10:50 – 11:30</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />12:45 – 13:45</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />11:45 – 12:25</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />12:20 – 12:50</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />11:35 – 11:55</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />13:45 – 14:00</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Break<br />12:25 – 12:40</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 5<br />12:55 – 13:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 5<br />12:00 – 12:45</td>
            </tr>

            <tr>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />14:05 – 14:55</td>
              <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />12:45 – 13:25</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 6<br />14:00 – 14:55</td>
              <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 6<br />12:50 – 13:25</td>
            </tr>


            <tr>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Activities<br />
                start @15:10</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Faculty / Departmental<br />
                Collaborative meetings</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>After school Activities<br />
                start @15:10</td>
              <td style={{ backgroundColor: '#f0f0f0' }}>Faculty / Departmental<br />
                Collaborative meetings</td>
            </tr>


          </tbody>
        </table>


        <p className="text-sm fst-italic text-link">
          The teaching and learning, Sunday to Thursday, is in person on the
          school campus. The distance learning option is no longer available.</p>



        <div className="contact-section col-lg-9 mx-auto mt-5">
          <h4 className="mt-3 page-title">Contact Us</h4>
          <p>If you have questions about the IKNS Bell Schedule, we are here to help.</p>
          <Link to="/contact-us" className="text-link">Go to contact form <i className="fas fa-arrow-right text-right"></i></Link>
        </div>


      </div>
      <Footer />




      {showPopup && (
        <>
          <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 999
          }} />
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0,0,0,0.3)',
            zIndex: 1000000
          }}>
            <button
              style={{
                position: 'absolute',
                right: '10px',
                top: '0px',
                border: 'none',
                background: 'none',
                fontSize: '30px',
                cursor: 'pointer'
              }}
              onClick={() => setShowPopup(false)}
            >
              ×
            </button>

            <>
              <div className="mt-3 d-flex justify-content-between align-items-center">
                <p className="fw-bold mb-0">The Holy Month of Ramadan Bell Schedule 2025 Wishing you a blessed month</p>
                <small><a href="https://drive.google.com/file/d/1FKz4qWT1lNuIIWP9MGvp-Dy-LBle0oJy/view?usp=sharing" className="text-primary" target="_blank" rel="noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-printer" viewBox="0 0 16 16"> <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" /> <path d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z" /> </svg> Print</a></small>
              </div>

              <table className="table table-bordered text-xs align-middle">
                <thead>
                  <tr className="align-middle text-center">
                    <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }} colSpan={3}>Kindergarten & Elementary School</th>
                    <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }} colSpan={2}>Middle & Secondary School</th>
                  </tr>

                  <tr className="align-middle text-center">
                    <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Kindergarten 1 & 2</th>
                    <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Grades 1, 2 & 3</th>
                    <th style={{ backgroundColor: '#2b6f6f', color: '#fafafa', border: '1px solid #fff' }}>Grades 4 & 5</th>
                    <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }}>Grades 6 - 8 </th>
                    <th style={{ backgroundColor: '#923535', color: '#fafafa', border: '1px solid #fff' }}>Grades 9 - 12</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  <tr>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br />08:55 - 09:30</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br />08:55 - 09:35</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 1<br />08:55 - 09:35</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 1<br />09:00 - 09:40</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 1<br />09:00 - 09:40</td>
                  </tr>


                  <tr>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />09:30 - 10:05</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />09:40 - 10:15</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 2<br />09:40 - 10:15</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 2<br />09:45 - 10:25</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 2<br />09:45 - 10:25</td>
                  </tr>

                  <tr>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>1st Break<br />10:05 - 10:30</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>1st Break<br />10:15 - 10:40</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />10:20 - 10:55</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 3<br />10:30 - 11:10</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>1st Break<br />10:25 - 10:40</td>
                  </tr>

                  <tr>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />10:35 - 11:10</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 3<br />10:45 - 11:20</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>1st Break<br />10:55 - 11:20</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>1st Break<br />11:10 - 11:25</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 3<br />10:45 - 11:25</td>
                  </tr>


                  <tr>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />11:10 - 11:45</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />11:25 - 12:00</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 4<br />11:25 - 12:00</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 4<br />11:30 - 12:10</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 4<br />11:30 - 12:10</td>
                  </tr>


                  <tr>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>2nd Break<br />11:45 - 12:00</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>2nd Break<br />12:00 - 12:15</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />12:05 - 12:40</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 5<br />12:15 - 12:55</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>2nd Break<br />12:10 - 12:25</td>
                  </tr>


                  <tr>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />12:05 - 12:40</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 5<br />12:20 - 12:55</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>2nd Break<br />12:40 - 12:55</td>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>2nd Break<br />12:55 - 13:10</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 5<br />12:30 - 13:10</td>
                  </tr>

                  <tr>
                    <td style={{ backgroundColor: '#f0f0f0', border: '1px solid #fff' }}>*</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />13:00 - 13:35</td>
                    <td style={{ backgroundColor: '#2b6f6f5c', border: '1px solid #fff' }}>Period 6<br />13:00 - 13:35</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 6<br />13:15 - 13:55</td>
                    <td style={{ backgroundColor: '#92353533', border: '1px solid #fff' }}>Period 6<br />13:15 - 13:55</td>
                  </tr>

                </tbody>
              </table>
              <p className="text-sm text-danger">* KG Students will be dismissed at 12:40pm. Parents of KG students who have children in the Elementary section of the school may pick up their KG children with Elementary siblings at 13:35 pm.</p>
              <p className="text-sm text-danger">** After School clubs and activities are cancelled during the Month of Ramadan</p>
            </>
          </div>
        </>
      )}


    </div>
  )
}


